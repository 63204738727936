<script setup>
import moment from "moment";
import i18n from "i18next";
import { useStoryblok } from "../../hooks/useStoryblok";
import { readDataFromLocalStorage } from "../../utils/utils";
import { capitaliseString } from "./../../utils/utils";
import {
  ALERT_DURATION,
  DATE_FORMAT,
  DOES_NOT_APPLY,
  TO_DO,
  DONE,
} from "./../../config/constants";
import { postAppraisalData } from "../../dao/appraisal";
import { STORYBLOK_VERSION_PARAM } from "../../config/constants";

defineProps({
  refreshChecklistMenu: Function,
  pageData: Object,
  locationName: String,
  appraisal: Object,
});
</script>

<template>
  <v-alert
    :type="alertStatus"
    :title="alertTitle"
    :text="alertText"
    v-model="alertVisible"
    closable
    close-label="Close Alert"
  ></v-alert>
  <div class="mt-8">
    <div>
      <p class="input-label">{{ $t("currentSituation") }}</p>
      <v-btn
        v-for="option in options"
        :key="option"
        elevation="0"
        class="situation-button"
        width="180px"
        @click="updateSituation(option)"
        :class="[option === situation ? 'bg-primary' : '']"
        :name="$t(option)"
      >
        {{ $t(option) }}
        <v-icon v-if="option === situation" icon="mdi-check" class="ml-2" />
      </v-btn>
    </div>

    <div class="mt-8">
      <p class="input-label">{{ $t("comments") }}</p>
      <v-textarea
        variant="outlined"
        :model-value="comment"
        @input="updateComment($event.target.value)"
        class="bg-white"
        :hide-details="'auto'"
        clearable
        @click:clear="updateComment('')"
        name="Appraisal comment"
        :placeholder="$t('comments')"
      />
      <i18next :translation="$t('disclaimer')">
        <template #sensitiveInformation>
          <a
            href="https://securityprivacy.pon.com/pon-data/uploads/2022/11/05-ST01-Information-classification-and-handling.docx.pdf"
            rel="nofollow noreferrer"
            target="_blank"
          >
            {{ $t("sensitiveInformation") }}
          </a>
        </template>
      </i18next>
    </div>
    <v-btn
      class="text-none elevation-0 bg-primary font-weight-bold mt-4"
      @click="submitAppraisal()"
      :loading="loading"
      name="Save appraisal"
    >
      {{ $t("save") }}
    </v-btn>
    <p class="mt-6" v-if="lastUpdatedBy !== null && lastUpdatedDate !== null">
      <i18next :translation="$t('lastUpdated')">
        <template #name>
          {{ lastUpdatedBy }}
        </template>
        <template #date>
          {{ lastUpdatedDate }}
        </template>
      </i18next>
    </p>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      options: [DOES_NOT_APPLY, TO_DO, DONE],
      situation: "",
      comment: "",
      loading: false,
      selectedOpCo: null,
      selectedSite: null,
      opcoName: null,
      opcoSite: null,
      lastUpdatedBy: null,
      lastUpdatedDate: null,
      name: null,
      alertVisible: false,
      alertStatus: null,
      alertTitle: "",
      alertText: "",
    };
  },
  created() {
    this.$auth.getUser().then((response) => (this.name = response.name));
    const selectedOpCoId = readDataFromLocalStorage("selectedOpCo", "uuid");
    const selectedSiteId = readDataFromLocalStorage("selectedSite", "uuid");
    if (
      typeof selectedOpCoId !== "undefined" ||
      typeof selectedSiteId !== "undefined"
    ) {
      this.selectedOpCo = selectedOpCoId;
      this.selectedSite = selectedSiteId;
      this.fetchOpCoData();
    }

    this.$watch(
      () => this.$route.params,
      () => {
        // Clear the alert and appraisal data to prevent flowing into new checklist page.
        this.alertVisible = false;
        this.prefillAppraisalData();
      },
      // fetch the data when the view is created and the data is
      // already being observed.
      { immediate: true }
    );
  },
  watch: {
    alertVisible: function (showAlert) {
      if (showAlert) {
        setTimeout(() => {
          this.alertVisible = false;
        }, ALERT_DURATION);
      }
    },
  },
  methods: {
    async fetchOpCoData() {
      const options = new URLSearchParams({
        version: STORYBLOK_VERSION_PARAM,
        find_by: "uuid",
      });
      const { data: opcoData } = await useStoryblok(
        `${this.selectedOpCo}/?${options.toString()}`
      ).json();

      const { data: siteData } = await useStoryblok(
        `${this.selectedSite}/?${options.toString()}`
      ).json();

      this.opcoName = opcoData.value.story.name;
      this.opcoSite = siteData.value.story.name;
    },
    prefillAppraisalData() {
      if (this.appraisal) {
        this.situation = this.appraisal.currentSituation;
        this.comment = this.appraisal.comments;
        this.lastUpdatedBy = this.appraisal.createdByName;
        this.lastUpdatedDate = moment(this.appraisal.updatedAt).format(
          DATE_FORMAT
        );
      } else {
        // Empty the input fields if there is no previous appraisal.
        this.comment = "";
        this.situation = "";
        this.lastUpdatedBy = null;
        this.lastUpdatedDate = null;
      }
    },
    updateSituation(newSituation) {
      this.situation = newSituation;
    },
    updateComment(newComment) {
      this.comment = newComment;
    },
    showAppraisalAlert(status, title, text) {
      this.alertVisible = true;
      this.alertStatus = status;
      this.alertTitle = title;
      this.alertText = text;
    },
    async submitAppraisal() {
      this.loading = true;

      if (this.situation || this.comment) {
        const submitData = {
          interventionId: this.pageData.story.uuid,
          interventionLabel: this.pageData.story.name,
          opcoId: this.selectedOpCo,
          opcoLabel: this.opcoName,
          siteId: this.selectedSite,
          siteLabel: this.opcoSite,
          locations: [
            {
              id: this.pageData.story.content.locationCategory,
              label: this.locationName,
            },
          ],
          comments: this.comment,
          createdByName: this.name,
        };

        if (this.situation) {
          submitData.currentSituation = this.situation;
        }

        let alertTitle, alertMessage, alertStatus;

        await postAppraisalData(submitData)
          .then(() => {
            alertStatus = "success";
            alertTitle = i18n.t("successfulSubmitTitle");
            alertMessage = i18n.t("successfulSubmitText");
            this.lastUpdatedBy = this.name;
            this.lastUpdatedDate = moment().format(DATE_FORMAT);
            this.lastUpdatedDate = moment().format(DATE_FORMAT);
            this.refreshChecklistMenu();
          })
          .catch((err) => {
            alertStatus = "error";
            alertTitle = i18n.t("errorTitle");
            alertMessage = err.message;
          });

        this.showAppraisalAlert(
          alertStatus,
          alertTitle,
          capitaliseString(alertMessage)
        );
      }

      this.loading = false;
    },
  },
};
</script>

<style scoped lang="scss">
.situation-button {
  border: 2px solid;
  border-color: rgb(var(--v-theme-primary));
  margin-top: 4px;
  margin-bottom: 4px;
  border-radius: 24px;
  text-transform: unset;

  @media (min-width: 960px) {
    margin: 0;
    margin-left: 4px;
    margin-right: 4px;
    border-radius: 0;
  }

  &:first-of-type {
    @media (min-width: 960px) {
      border-top-left-radius: 24px;
      border-bottom-left-radius: 24px;
    }
  }

  &:last-of-type {
    @media (min-width: 960px) {
      border-top-right-radius: 24px;
      border-bottom-right-radius: 24px;
    }
  }

  &:first-of-type,
  &:last-of-type {
    margin: 0;
  }
}
</style>
