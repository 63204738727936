<script setup>
import { useStoryblok } from "./../../hooks/useStoryblok";
import PonLogo from "../../icons/PonLogo.vue";
import PonLogoMobile from "../../icons/PonLogoMobile.vue";
import MenuItem from "./MenuItem.vue";
import { STORYBLOK_VERSION_PARAM } from "../../config/constants";

defineProps({ activeUser: Object });
</script>

<template>
  <div class="visually-hidden-focusable bg-secondary">
    <v-container>
      <a href="#content">{{ $t("skip") }}</a>
    </v-container>
  </div>
  <v-app-bar id="header" class="bg-primary" height="80" floating="true">
    <v-app-bar-title>
      <router-link to="/" class="logo-focusable" aria-label="Link to home page">
        <PonLogo
          class="d-md-block hidden-sm-and-down"
          alt="Pon Accessibility Toolkit logo for desktop"
        />
        <PonLogoMobile
          class="d-block d-md-none hidden-md-and-up"
          alt="Pon Accessibility Toolkit logo for mobile"
        />
      </router-link>
    </v-app-bar-title>
    <v-toolbar-items
      class="hidden-sm-and-down py-4"
      role="listbox"
      aria-label="Navigation items"
    >
      <MenuItem
        v-for="item in menuItems"
        :key="item._uid"
        :title="item.name"
        :url="item.link.url"
      />
    </v-toolbar-items>
    <div class="hidden-md-and-up" @click.stop="drawer = !drawer">
      <span>{{ $t("menu") }}</span>
      <v-app-bar-nav-icon aria-label="Hamburger menu toggle" />
    </div>
  </v-app-bar>
  <v-navigation-drawer
    temporary
    v-model="drawer"
    class="bg-primary hidden-md-and-up"
  >
    <v-list aria-label="Navigation items">
      <MenuItem
        v-for="item in menuItems"
        :key="item._uid"
        :title="item.name"
        :url="item.link.url"
        class="mx-4"
      />
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  data: (instance) => ({
    drawer: false,
    menuItems: [],
    activeUser: instance.activeUser,
  }),
  created() {
    this.$watch(
      () => this.activeUser,
      () => {
        this.activeUser && this.menuItems.length === 0 && this.fetchMenuData();
      },
      // fetch the data when the view is created and the data is
      // already being observed.
      { immediate: true }
    );
  },
  methods: {
    async fetchMenuData() {
      const MENU_PARAMS = new URLSearchParams({
        version: STORYBLOK_VERSION_PARAM,
      }).toString();
      const PAGE_SLUG = "/navigation";

      const { data } = await useStoryblok(`${PAGE_SLUG}?${MENU_PARAMS}`).json();
      this.menuItems = data.value.story.content.menu;
    },
  },
};
</script>

<style lang="scss" scoped>
#header {
  position: sticky !important;
}
.logo-focusable {
  svg {
    // Add margin to give space to the outline.
    margin: 2px;
  }
  &:focus-visible {
    outline-offset: 0;
    text-decoration: none;
    outline: none;
    svg {
      border-radius: 4px;
      outline: solid 2px rgb(var(--v-theme-secondary));
    }
  }
}
.visually-hidden-focusable {
  a {
    color: #fff;
  }
  &:not(:focus):not(:focus-within) {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important;
  }
}
</style>
