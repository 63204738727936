<script setup>
import {
  INTERVENTION_STATUS,
  DONE,
  TO_DO,
  DOES_NOT_APPLY,
} from "../../config/constants";
import { capitaliseString } from "../../utils/utils";
import SiteSelector from "../../components/SiteSelector.vue";
defineProps({
  title: String,
  items: Object,
  isChecklistPage: Boolean,
  isInDrawer: Boolean,
});
</script>

<template>
  <h2 :class="isInDrawer && 'hidden-xs'" data-cy="heading">
    {{ capitaliseString(title.split("/")[0].replaceAll("-", " ")) }}
  </h2>
  <SiteSelector :class="isInDrawer && 'hidden-xs'" v-show="isChecklistPage" />
  <v-divider class="mt-6 mb-2 hidden-xs divider"></v-divider>
  <v-list flat name="List of pages">
    <v-list-item-group v-for="item in items" :key="item.name" role="option">
      <v-list-item class="pl-0">
        <v-list-item-title class="font-weight-bold">
          {{ item.name }}
        </v-list-item-title>
      </v-list-item>
      <v-list-item
        v-show="child.visible !== false"
        :active="child.active"
        link
        v-for="child in item.children"
        :key="child.name"
        :to="child.target"
        class="outline-focus rounded mx-1"
        :class="
          typeof child.situation !== 'undefined'
            ? `${getStatusColor(child.situation)} intervention-status`
            : ''
        "
        :name="`Intervention for ${child.name}`"
      >
        <v-list-item-content>
          <v-list-item-title>
            {{ child.name }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>

    <router-link
      v-show="isChecklistPage"
      to="/report"
      class="text-primary text-center text-decoration-none"
      ><v-btn class="mt-4 text-none w-50" variant="outlined" elevation="0">
        {{ $t("fullReport") }}
        <v-icon icon="mdi-chart-bar" size="small" class="ml-2" />
      </v-btn>
    </router-link>
  </v-list>
</template>

<script>
function getStatusColor(situation) {
  if (situation === TO_DO) {
    return INTERVENTION_STATUS.TO_DO;
  } else if (situation === DONE) {
    return INTERVENTION_STATUS.DONE;
  } else if (situation === DOES_NOT_APPLY) {
    return INTERVENTION_STATUS.DOES_NOT_APPLY;
  } else {
    return "none";
  }
}
</script>
